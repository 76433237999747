import React from "react";
import * as FaIcon from "react-icons/fa";
import {
  MdBusinessCenter,
  MdDashboard,
  MdDashboardCustomize,
  MdGroup,
  MdPersonOutline,
} from "react-icons/md";
import { AlignCenter, ArrowUp, File, FileMinus } from "react-feather";

import { SiReadthedocs } from "react-icons/si";
import { GiStamper, GiCash } from "react-icons/gi";
import {
  BsPersonFillCheck,
  BsPersonBoundingBox,
  BsBuildingCheck,
  BsHouseCheck,
  BsBank2,
  BsUiChecks,
} from "react-icons/bs";
import { TbUserExclamation } from "react-icons/tb";
import { BiTachometer } from "react-icons/bi";
import { FaBan, FaRegFilePdf, FaRegFileExcel } from "react-icons/fa";
import { ImUserCheck, ImCancelCircle } from "react-icons/im";
import { GiVillage, GiRotaryPhone } from "react-icons/gi";
import { AiOutlineTrophy } from "react-icons/ai";
import { FaPeopleRoof, FaPeopleGroup } from "react-icons/fa6";
import { PiShieldWarningBold } from "react-icons/pi";
import { MdPendingActions } from "react-icons/md";
import { RiPassValidLine } from "react-icons/ri";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "collapse",
    icon: <MdDashboard size={20} />,
    navLink: "/",
    requiredFeature: [],
    children: [
      {
        id: "business",
        title: "Business",
        type: "item",
        icon: <MdBusinessCenter size={20} />,
        navLink: "/",
        requiredFeature: "",
      },
      {
        id: "collection_dashboard",
        title: "Collection",
        type: "item",
        icon: <MdDashboardCustomize size={20} />,
        navLink: "/dashboard/collection",
        requiredFeature: "collection_dashboard",
      },
    ],
  },
  {
    id: "leaderboard",
    title: "Leaderboard",
    type: "item",
    icon: <AiOutlineTrophy size={20} />,
    navLink: "/leaderboard",
    requiredFeature: ["features_leadr_dashboard"],
  },
  {
    id: "Customer",
    title: "Customer",
    type: "item",
    icon: <BsPersonBoundingBox size={20} />,
    navLink: "/customers",
    requiredFeature: ["features_customer"],
  },
  {
    id: "applications",
    title: "Applications",
    type: "item",
    icon: <FaIcon.FaFileAlt size={20} />,
    navLink: "/applications",
    requiredFeature: ["applications_list_view"],
  },
  {
    id: "village",
    title: "Village",
    type: "item",
    icon: <GiVillage size={20} />,
    navLink: "/village",
    requiredFeature: ["features_api_village_creation"],
  },
  {
    id: "centers",
    title: "Centers",
    type: "item",
    icon: <AlignCenter size={20} />,
    navLink: "/centers",
    requiredFeature: ["features_center_group_list"],
  },
  {
    id: "quality_check_ready",
    title: "Data Verification",
    type: "item",
    icon: <BsUiChecks size={20} />,
    navLink: "/quality_check_ready",
    requiredFeature: ["features_quality_check"],
  },
  {
    id: "televerification",
    title: "Tele Verification",
    type: "item",
    icon: <FaIcon.FaPhoneAlt size={20} />,
    navLink: "/tele_verification_list",
    requiredFeature: ["features_tele_verification"],
  },
  {
    id: "groups",
    title: "Groups",
    type: "item",
    icon: <MdGroup size={20} />,
    navLink: "/groups",
    requiredFeature: ["features_center_group_list"],
  },
  // {
  //   id: "quality_check_ready",
  //   title: "Quality Check",
  //   type: "item",
  //   icon: <BsUiChecks size={20} />,
  //   navLink: "/quality_check_ready",
  //   requiredFeature: ["features_scrutiny_check"],
  // },
  // {
  //   id: "CGT-1",
  //   title: "CGT-1",
  //   type: "item",
  //   icon: <HiOutlineCurrencyRupee size={20} />,
  //   navLink: "/cgt-1",
  // },
  // {
  //   id: "CGT-2",
  //   title: "CGT-2",
  //   type: "item",
  //   icon: <HiOutlineCurrencyRupee size={20} />,
  //   navLink: "/cgt-2",
  // },
  // {
  //   id: "GRT",
  //   title: "GRT",
  //   type: "item",
  //   icon: <HiOutlineCurrencyRupee size={20} />,
  //   navLink: "/grt",
  // },
  // {
  //   id: "Originator",
  //   title: "Originator",
  //   type: "collapse",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   badgeText: "2",
  //   children: [
  //     {
  //       id: "Applications",
  //       title: "Applications",
  //       type: "collapse",
  //       icon: <SiApplearcade size={20} />,
  //       children: [
  //         {
  //           id: "individualLoans",
  //           title: "Individual Applications",
  //           type: "item",
  //           icon: <RiAppsLine size={22} />,
  //           navLink: "/applications/individual",
  //         },
  //         {
  //           id: "smeLoans",
  //           title: "SME Applications",
  //           type: "item",
  //           icon: <IoLogoAppleAr size={22} />,
  //           navLink: "/applications/sme",
  //         },
  //       ],
  //     },
  //     {
  //       id: "Customers",
  //       title: "Customers",
  //       type: "collapse",
  //       icon: <FaUserTie size={20} />,
  //       children: [
  //         {
  //           id: "individualCustomer",
  //           title: "Individual Customers",
  //           type: "item",
  //           icon: <MdPersonOutline size={22} />,
  //           navLink: "/customers/individual",
  //         },
  //         {
  //           id: "smeCustomer",
  //           title: "SME Customers",
  //           type: "item",
  //           icon: <BsPersonCircle size={22} />,
  //           navLink: "/customers/sme",
  //         },
  //       ],
  //     },
  //     {
  //       id: "Originatorreports",
  //       title: "Reports",
  //       type: "item",
  //       icon: <FaIcon.FaClipboardList size={20} />,
  //       navLink: "/originator_reports",
  //     },
  //     {
  //       id: "partners",
  //       title: "Partners",
  //       type: "item",
  //       icon: <FaRegHandshake size={24} />,
  //       navLink: "/organisation/partners",
  //     },
  //   ],
  // },
  // {
  //   id: "products",
  //   title: "Products",
  //   type: "item",
  //   icon: <TbBox size={24} />,
  //   navLink: "/organisation/products",
  // },
  // {
  //   id: "fees_collection",
  //   title: "Fees Collection",
  //   type: "item",
  //   icon: <TbBox size={24} />,
  //   navLink: "/fees-collection/applications",
  // },
  // {
  //   id: "generate_kit",
  //   title: "Generate Kit",
  //   type: "item",
  //   icon: <TbBox size={24} />,
  //   navLink: "/generate-kit/applications",
  // },
  {
    id: "pgkit",
    title: "PG Kit",
    type: "item",
    icon: <RiPassValidLine size={20} />,
    navLink: "/pg_kit",
    requiredFeature: ["feature_pg_kit"],
  },
  {
    id: "disbursement",
    title: "Disbursement",
    type: "collapse",
    icon: <FaIcon.FaRupeeSign size={20} />,
    navLink: "",
    requiredFeature: ["features_disbursement"],
    children: [
      {
        id: "document_generation_ready",
        title: "Document Generation",
        type: "item",
        icon: <FaRegFilePdf size={20} />,
        navLink: "/check_disbursement",
        requiredFeature: "",
      },
      {
        id: "sign_ready",
        title: "E-Sign Ready",
        type: "item",
        icon: <GiStamper size={20} />,
        navLink: "/esign_ready",
        requiredFeature: "",
      },
      {
        id: "disbursed",
        title: "Disbursed",
        type: "item",
        icon: <GiCash size={20} />,
        navLink: "/disbursed_list",
        requiredFeature: "",
      },
    ],
  },
  {
    id: "faileddropdown",
    title: "Failed List",
    type: "collapse",
    icon: <FileMinus size={20} />,
    requiredFeature: ["features_scrutiny_check"],
    children: [
      {
        id: "aml_qc",
        title: "AML Fail",
        type: "item",
        icon: <PiShieldWarningBold size={20} />,
        navLink: "/aml_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "bre_qc",
        title: "BRE Fail",
        type: "item",
        icon: <FaIcon.FaSitemap size={20} />,
        navLink: "/bre_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "bank_qc",
        title: "BKV Fail",
        type: "item",
        icon: <BsBank2 size={20} />,
        navLink: "/bank_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "cgt_qc",
        title: "CGT Fail",
        type: "item",
        icon: <FaPeopleGroup size={20} />,
        navLink: "/cgt_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "hv_qc",
        title: "HV Fail",
        type: "item",
        icon: <BsHouseCheck size={20} />,
        navLink: "/hv_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "bv_qc",
        title: "BV Fail",
        type: "item",
        icon: <BsBuildingCheck size={20} />,
        navLink: "/bv_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "tv_qc",
        title: "TV Fail",
        type: "item",
        icon: <GiRotaryPhone size={20} />,
        navLink: "/tv_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "grt_qc",
        title: "GRT Fail",
        type: "item",
        icon: <FaPeopleRoof size={20} />,
        navLink: "/grt_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "esign_qc",
        title: "ESIGN Fail",
        type: "item",
        icon: <FaIcon.FaFileSignature size={20} />,
        navLink: "/esign_qc",
        requiredFeature: "features_scrutiny_check",
      },
      {
        id: "id_validation_qc",
        title: "ID Fail",
        type: "item",
        icon: <RiPassValidLine size={20} />,
        navLink: "/id_validation_qc",
        requiredFeature: "features_scrutiny_check",
      },
    ],
  },
  {
    id: "rejected_cases",
    title: "Rejected Cases",
    type: "collapse",
    icon: <FaBan size={20} />,
    navLink: "",
    requiredFeature: ["features_rejected_cases"],
    children: [
      {
        id: "rejected_app",
        title: "Rejected Applications",
        type: "item",
        icon: <FaRegFileExcel size={20} />,
        navLink: "/rejected_applications",
        requiredFeature: "features_rejected_cases",
      },
      {
        id: "rejected_customers",
        title: "Rejected Customers",
        type: "item",
        icon: <TbUserExclamation size={20} />,
        navLink: "/rejected_customers",
        requiredFeature: "features_rejected_cases",
      },
    ],
  },
  {
    id: "pending_state",
    title: "CBS Pending ",
    type: "collapse",
    icon: <MdPendingActions size={20} />,
    navLink: "",
    requiredFeature: ["features_pending_cases"],
    children: [
      {
        id: "center_pending",
        title: "Centers",
        type: "item",
        icon: <AlignCenter size={20} />,
        navLink: "/center_pending",
        requiredFeature: "features_pending_cases",
      },
      {
        id: "group_pending",
        title: "Groups",
        type: "item",
        icon: <MdGroup size={20} />,
        navLink: "/group_pending",
        requiredFeature: "features_pending_cases",
      },
      {
        id: "customer_pending",
        title: "Customers",
        type: "item",
        icon: <BsPersonBoundingBox size={20} />,
        navLink: "/customer_pending",
        requiredFeature: "features_pending_cases",
      },
      {
        id: "application_pending",
        title: "Applications",
        type: "item",
        icon: <FaIcon.FaFileAlt size={20} />,
        navLink: "/application_pending",
        requiredFeature: "features_pending_cases",
      },

      {
        id: "disbursement_pending",
        title: "Disbursements",
        type: "item",
        icon: <FaIcon.FaRupeeSign size={20} />,
        navLink: "/disbursement_pending",
        requiredFeature: "features_pending_cases",
      },
      {
        id: "collection_pending",
        title: "Collections",
        type: "item",
        icon: <BsPersonBoundingBox size={20} />,
        navLink: "/collection_pending",
        requiredFeature: "features_pending_cases",
      },
      {
        id: "pending_dms",
        title: "DMS",
        type: "item",
        icon: <File size={20} />,
        navLink: "/application_dms_pending",
        requiredFeature: "features_pending_cases",
      },
    ],
  },
  {
    id: "preclosure_entry",
    title: "Pre-closure Entry",
    type: "collapse",
    icon: <File size={20} />,
    navLink: "",
    requiredFeature: ["features_checker", "features_maker"],
    children: [
      {
        id: "initiated_app",
        title: "Initiate",
        type: "item",
        icon: <ArrowUp size={25} />,
        navLink: "/pre-closure/initiated",
        requiredFeature: "features_maker",
      },
      {
        id: "maker_apps",
        title: "Maker",
        type: "item",
        icon: <BsPersonFillCheck size={20} />,
        navLink: "/pre-closure/maker",
        requiredFeature: "features_maker",
      },
      {
        id: "checker_apps",
        title: "Checker",
        type: "item",
        icon: <FaIcon.FaCheck size={20} />,
        navLink: "/pre-closure/checker",
        requiredFeature: "features_checker",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    type: "item",
    icon: <FaIcon.FaClipboardList size={20} />,
    navLink: "/generate_reports",
    requiredFeature: ["accounts_reports"],
  },
  {
    id: "bre",
    title: "BRE",
    type: "item",
    icon: <FaIcon.FaSitemap size={20} />,
    navLink: "/bre",
    requiredFeature: ["features_bre"],
  },
  {
    id: "score",
    title: "Scoring Rules",
    type: "item",
    icon: <BiTachometer size={20} />,
    navLink: "/score",
    requiredFeature: ["features_products"],
  },
  {
    id: "verify",
    title: "Collection Verification",
    type: "item",
    icon: <FaIcon.FaCheck size={20} />,
    navLink: "/collections/verify",
    requiredFeature: ["features_maker"],
  },

  // {
  //   id: "death_initiation",
  //   title: "Death Initiation",
  //   type: "collapse",
  //   icon: <ImCancelCircle size={20} />,
  //   requiredFeature: [],
  //   children: [
  //     {
  //       id: "death_verify",
  //       title: "Death Verification",
  //       type: "item",
  //       icon: <FaIcon.FaCheck size={20} />,
  //       navLink: "/death_initiation/verify",
  //       requiredFeature: "",
  //     },
  //     {
  //       id: "death_closing",
  //       title: "Death Closing",
  //       type: "item",
  //       icon: <FaIcon.FaTimes size={20} />,
  //       navLink: "/death_initiation/closing",
  //       requiredFeature: "",
  //     },
  //   ],
  // },
  {
    id: "death_initiation",
    title: "Death Initiation",
    type: "item",
    icon: <ImCancelCircle size={20} />,
    navLink: "/death_initiation/verify",
    requiredFeature: ["features_maker"],
  },
  {
    id: "organisations",
    title: "Organisations",
    type: "item",
    icon: <FaIcon.FaThList size={20} />,
    navLink: "/organisation",
    requiredFeature: ["features_organisation"],
  },
  // {
  //   id: "lms",
  //   title: "LMS",
  //   type: "item",
  //   icon: <FaIcon.FaExternalLinkAlt size={20} />,
  //   navLink: "#",
  // },
];

export default navigationConfig;
